<template>
  <power-bi-report :report-id="reportId" />
</template>

<script>
export default {
  components: {
    PowerBiReport: () => import('@/modules/external_bi/components/PowerBiReport')
  },
  computed: {
    reportId() {
      return '94a5ed81-29fa-4b2a-b360-1abb414b37c6'
    }
  }
}
</script>
